.login-container, .signup-container, .institute-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  background-color: #f8f9fa;
  padding: 40px 0;
  min-height: calc(100vh - 98px); /* Adjust 60px to match your navigation height */
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
}
.login-container{
  background-image:  url('../../imgs/png/10.png');
}
.signup-container{
  background-image:  url('../../imgs/png/8.png');
}
.institute-container{
  background-image:  url('../../imgs/png/12.png');
}
.button-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container button {
  padding: 0.75rem 2rem;
  margin: 0 1rem;
  cursor: pointer;
  border: none;
  /* font-size: 1rem; */
  position: relative;
  transition: all 0.3s;
}

.button-container button.active {
  background-color: #007BFF;
  color: #fff;
}

/* .button-container button.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #0056b3;
  position: absolute;
  bottom: -5px;
  left: 0;
} */

.login-content {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  /* border: 1px solid red; */
}


.login-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-btn {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #0056b3;
}

.login-options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.login-options a {
  text-decoration: none;
  color: #007bff;
  margin-right: 10px;
}

.login-options a:hover {
  text-decoration: underline;
}

.login-description {
  text-align: center;
}

.login-description h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.login-description p {
  font-size: 14px;
  color: #666;
}


.custom-login-button {
  text-decoration: none;
  font-size: 1.25rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  cursor: pointer;
  color: black;
  font-weight: 500;
  transition: background-color 0.3s;
  background-color: #f9fafb;
}

.custom-login-button:hover {
  background-color: #f3f4f6;
}

.custom-signup-button {
  text-decoration: none;
  font-size: 1.25rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-weight: 500;
  background-color: #3b82f6;
  color: white;
  transition: opacity 0.3s;
}

.custom-signup-button:hover {
  opacity: 0.85;
}

.custom-login-button,
.custom-signup-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
}

.custom-signup-button button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

@media (max-width: 640px) {
  .custom-login-button,
  .custom-signup-button {
    padding: 0.5rem 0.75rem;
  }
}
