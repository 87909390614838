/* .missionPicture{
    background-image: url("../../imgs/missimage.PNG");
    background-size: cover;
    width: 100%;
    height: 380px;

    background-color:rgba(0, 0, 0, 0.7);
}
.headingsmission{
    color: white;
    margin-left: 200px;
    
}

.missitem{
    display: flex;
    padding: 50px;
}
.misparag{
    
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
       margin-left: 80px;
       margin-top: 50px;
    color:rgba(0, 0, 0, 0.7)
}
.missionhead{
    margin-top: 50px;
    margin-left: 50px;
}
.ourmission{

 
        font-size: 45px;
        font-weight: 800;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: left;
}
.mipargra{


        font-size: 28px;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
} */






/* .missionPicture {
    background-image: url("../../imgs/missimage.PNG");
    background-size: cover;
    width: 100%;
    height: 380px;
    background-color: rgba(0, 0, 0, 0.7);
} */

/* Default styles for .missionPicture */
.missionPicture {
    background-image: url("../../imgs/missimage.PNG");
    background-size: cover;
    width: 100%;
    height: 380px;
    background-color: rgba(0, 0, 0, 0.7);
}

/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
    .missionPicture {
        height: 280px; /* Adjust the height for smaller screens */
    }
}

/* Media query for screens with a maximum width of 480px */
@media screen and (max-width: 480px) {
    .missionPicture {
        height: 180px; /* Further adjust the height for even smaller screens */
    }
}






.headingsmission {
    color: white;
    margin-left: 200px;
}

.missitem {
    display: flex;
    padding: 50px;
}

.misparag {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 80px;
    margin-top: 50px;
    color: rgba(0, 0, 0, 0.7);
}

.missionhead {
    margin-top: 50px;
    margin-left: 50px;
}

.ourmission {
    font-size: 45px;
    font-weight: 800;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
}

.mipargra {
    font-size: 28px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
}


@media screen and (max-width: 768px) {
    .missionPicture {
        height: 250px; 
    }

    .headingsmission {
        margin-left: 20px; 
    }

    .missitem {
        flex-direction: column; 
        padding:20px; 
    }

    .misparag {
        margin-left: 20px; 
        margin-top: 20px; 
    }

    .missionhead {
        margin-left: 20px; 
        margin-top: 20px; 
    }

    .ourmission {
        font-size: 36px; 
    }

    .mipargra {
        font-size: 24px; 
    }
}
