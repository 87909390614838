@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 50px;
  margin-bottom: 50px;
}


@media (min-width: 640px) {
  .main-container {
    max-width: 540px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 768px) {
  .main-container {
    max-width: 760px;
    
  }
}

@media (min-width: 1024px) {
  .main-container {
    max-width: 1000px;
    /* padding-left: 32px;
    padding-right: 32px; */
  }
}

@media (min-width: 1280px) {
  .main-container {
    max-width: 1200px;
  }
}

@media (min-width: 1536px) {
  .main-container {
    max-width: 1420px;
  }
}

.custom-title {
  text-align: center;
  font-size: 25px;
  color: #1babe4;
  font-weight: 600; 
  padding-bottom: 16px; 
  margin-bottom: 30px;  
}

/* on homepage */

.sharePlat{
  font-family: 'Poppins', sans-serif;
}