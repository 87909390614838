.communityItemo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.community-description {
  width: 1170px;
  height: 66px;
  margin-top: 60px;
  margin-bottom: 50px;
}

.community-paragraph {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}

.create-community-button {
  width: 222px;
  height: 54px;
  margin-bottom: 5px;
  background: #3305AE;
  border: 1px solid #3305AE;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}

.communities {
  height: 200px;
  margin-bottom: 30px;
}
.card-cont {
  border-radius: 0px !important;
}
/* .card {
  border-radius: 12px !important;
} */
.card-img {
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
}
.card-image {
  height: 160px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 12px;
  margin-left: 12px;
}

.card-text{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.join-button {
  width: 97px;
  height: 45px;
  border-radius: 8px;
  background-color: #D9D9D9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-style: bold;
}
.text-center {
  margin-top: 20px;
}
.custom-heading {
  background-color: #3305AE;
  color: white;
  padding: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}
.nav-tabs{
  border-bottom: 0px solid #dee2e6;
  margin-top: 20px;
  margin-bottom: 20px;
}