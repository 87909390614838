/* styles.css */

/* Hide the default radio button */
input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  
  /* Create a custom radio button */
  input[type="radio"] + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
  }
  
  /* Style the custom radio button */
  input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  /* Style the checked state */
  input[type="radio"]:checked + label:before {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Add a checkmark for the checked state */
  input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
  }