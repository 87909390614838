


.mainHome {
  width: 94vw;
  margin-inline: auto;
}

.brawsetop {
  color: #1BABE4;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}


/* my styles */
.bwengeNews {
  margin-top: 50px;
}

.trending {
  margin-bottom: 2%;
  margin-left: -5px;
}

.trending-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  /* letter-spacing: 0em; */
  text-align: right;
  margin-left: 5px;
}


.headings {
  margin-bottom: 2%;
}

.headings-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  margin-left: 10px;
}

.card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readmore {
  background: #04784A !important;
  color: #FFFFFF !important;
  border-radius: 6px !important;
  border: 1px solid #04784A !important;
  font-size: 14px !important;
  font-weight: 600;
}

.date {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 500;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-container img {
  margin-left: 10px;
}

.cards {
  max-width: 345px;
  margin: auto;
  position: relative;
}

.card-img-top {
    height: 150px; 
    object-fit: cover; 
}

.card-title {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limits the title to two lines */
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
}

.cards-body {
  width: 80%;
  position: absolute;
  bottom: -95%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.heading-date {
  color: rgba(0, 0, 0, 0.6);
}

.blogrw {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.courses {
  border-radius: 5px !important;
  margin-bottom: 15px;
}

.join-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.join-btn {
  display: inline-block;
  width: 25%;
  margin-left: 40px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #04784A;
  border: 1px solid #04784A;
  padding: .375rem .75rem;
  font-size: 14px;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.join-btn:hover {
  color: #fff;
}

.book-btn {
  display: inline-block;
  width: 25%;
  margin-left: 40px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #007EB0;
  border: 1px solid #007EB0;
  padding: .375rem .75rem;
  font-size: 14px;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.book-btn:hover {
  color: #fff;
}

.meeting-card {
  border-radius: 5px !important;
}

.live-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  margin-left: 5px;
  margin-bottom: 10px;
}

.live-container {
  margin-bottom: 3%;
}

.people-icon {
  margin-left: 60px;
}

.meeting-title {
  margin-bottom: 12px;
}

.nsangiza-button-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  /* justify-content: space-between; */
}

.nsangiza-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 198px;
  height: 52px;
  background-color: #989898;
  border: 1px solid #989898;
  border-radius: 4px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 0 10px;
  margin: 0 5px;
}

.nsangiza-btn img {
  margin-right: 10px;
}




.book{
  display: flex;
  width: 100%;
  
}

/*
.bookimu {
  margin-left:95px;
}
.bookimuu{
  margin-left: 65px;
}


.storebook{

  margin-top: 80px;
  display: inline-flex;
  justify-content: space-between;
  margin-left: 10px;
}

.storeimg{
  height: 60px;
    width: 100px;
    left: 10px;
    top: 183px;
    color: #1BABE4;
}

.headingbookstore {
  margin-top: 30px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
*/


.storebook {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
}

.storeimg {
  height: auto;
  max-width: 100%;
  margin-right: 10px;
  color: #1BABE4;
}

.headingbookstore {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
}

.book {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: 10px;
}

.bookimu {
  margin-left: 20px;
}

.bookimuu {
  margin-left: 20px;
}

.p2 {
  font-size: 14px;
}


@media (max-width: 768px) {
  .book {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-left: 0;
  }

  .storeimg {
    margin-right: 0;
  }

  .headingbookstore {
    font-size: 16px;

  }


  .bookimu,
  .bookimuu {
    margin-left: 0;
    align-items: center;
  }
}


.paragarph {
  margin-left: 100px;
}

.paragarphii {
  margin-left: 80px;
}

.paragarphii {
  margin: 5px;
}




/* .partner{
  display: inline-flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 200px;
    justify-items: center;
  
}
.partner1{
  height: 226px;
  width: 224px;
  margin-top: 20px;

}
.partner2{

  height: 308px;
    width: 306px;
    margin-left: 80px;
}
.partner3{

  height: 226px;
  width: 224px;
  margin-left: 80px;
  margin-top: 20px;
   
}
.parheading{
  
    font-size: 23px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #1BABE4;
}
.partnerdivision{
margin-top: 80px;
} */



/* .partner {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-left: 200px;
  justify-items: center;
}

.partner1 {
  height: 226px;
  width: 224px;
  margin-top: 20px;
}

.partner2 {
  height: 308px;
  width: 306px;
  margin-left: 80px;
}

.partner3 {
  height: 226px;
  width: 224px;
  margin-left: 80px;
  margin-top: 20px;
}

.parheading {
  font-size: 23px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #1BABE4;
}

.partnerdivision {
  margin-top: 80px;
}


@media (max-width: 768px) {
  .partner {
    flex-direction: row; 
    align-items: center; 
    margin-left: 0; 
    justify-content: space-between;
  }

  .partner1,
  .partner2,
  .partner3 {
    margin-left: 0; 
  }


  .partner1,
  .partner3 {
    margin-top: 10px;
  }
}


@media (max-width: 768px) {
  .partner1,
  .partner2,
  .partner3 {
    width: 100%; 
    max-width: 300px; 
    margin: 10px auto; 
  }
}

 */


.partner-slideshow {
  overflow: hidden;
  max-width: 100%;
}


.partner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* animation: slide 12s linear infinite;  */
}

.partner1,
.partner2,
.partner3 {
  flex: 0 0 auto;
  width: 33.33%;
  text-align: center;
  padding: 20px;

}


@keyframes slide {

  0%,
  100% {
    transform: translateX(0);
  }

  33.33%,
  66.66% {
    transform: translateX(-100%);
  }
}


@media (max-width: 768px) {

  .partner {
    animation-duration: 6s;
    margin: 0
  }


  .partner1,
  .partner2,
  .partner3 {
    width: 60%;
  }

  .partner1__img,
  .partner2__img,
  .partner3__img {
    width: 30%
  }

  .partner {
    justify-content: flex-start;
    margin-left: 0;
  }
}


.ubumenyi {
  background: black;
  display: flex;
  width: 100%;
  height: 396px;
  margin-top: 80px;
  border-radius: 0px;
}


.headubumenyi {
  margin-left: 200px;
  margin-top: 50px;
}


@media screen and (max-width: 768px) {
  .ubumenyi {
    height: auto;
    padding: 20px;
    flex-direction: column;
    text-align: center;
  }

  .headubumenyi {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }

}

.imag3 {
  margin-left:50px;
}

@media screen and (max-width: 768px) {
  .imag3 {
    margin-left: 0;
    max-width: 100%;
  }
}

.imag3 {
  margin-left: 50px;
}

@media screen and (max-width: 768px) {
  .imag3 {
    margin-left: 0;
    max-width: 100%;
  }
}




.bwengeintro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-top: 60px;
}


.newcareerheading {

  color: #1BABE4;
  font-size: 26px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 15px;
}

.newcareerpara {

  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

}

.careerbtn {
  margin-left: 0;
  height: 66px;
  font-size: 21px;
  font-family: 'Roboto', Arial, sans-serif;
  border-radius: 8px;
  background: #04784A;
  color: white;
  width: 321px;


}

.imag1 {
  max-width: 100%;
  height: auto;
  display: block;
  /* margin: 0 auto; */
}

@media (max-width: 764px) {
  .imag1 {

    max-width: 100%;
    height: auto;

  }
}

@media screen and (max-width: 768px) {
  .bwengeintro {
    flex-direction: column;
    text-align: center;

    .header-image {
      margin-bottom: 15px;
    }

    .newcareerheading,
    .careerbtn {
      margin: 15px 0;
      font-size: 20px;
      font-weight: 800;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;

    }

  }

  .newcareerpara {
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

  }
}







.main {
  background: url("../../imgs/ai.jpeg");
  background-size: cover;
  min-height: 60vh;
  margin-top: 3rem;
  border-radius: 20px;
}

.wel {
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 100px;
  margin-top: 80px;
}

@media (max-width: 726px) {
  .wel {
    font-size: 25px;
  }

}

.main .big_header {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  line-height: 3rem;
}

.main .small_header {
  font-size: 2rem;
  color: #fff;
  line-height: 2.5rem;
  margin-top: 1.2rem;
  line-height: 3rem;
}



.btn {
  font-size: 1.2rem;
  color: #000000;

  border: 2px solid black;
  border-radius: 10px;
}

.bt1 {
  height: 50px;
  font-size: 1.5rem;
  width: 150px;

  background-color: yellow;
}

.btn a {
  text-decoration: none;
  color: black;
}

.header-image {

  animation: bounceTop-1 3s ease infinite;
}

.project,
.course {
  margin-top: 80px;
  width: 90vw;
  min-height: 95vh;
}

.head1 {
  margin-top: 60px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 23px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

}

.p1 {
  margin-top: 30px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

}

@media (max-width: 992px) {
  .head1 {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;


  }

  .p1 {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;

  }
}

@media (max-width: 768px) {
  .head1 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .p1 {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}






/* .head1 {
  margin-top: 60px;
  font-size: 2rem;
  padding: 1.2rem;
  font-weight: bold;
  line-height: 2.5rem;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0em;
   text-align: left;
}


.p1 {
  font-size: 24px;
  margin-top: 10px;
  padding: 1.2rem;
  line-height: 2rem;
  font-family: Roboto;
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}


.blogrw {
  height: 373px;
    width: 820px;
    left: 412px;
    top: 2954px;
    border-radius: 0px;
    background: #FFFFFF;
   
}

.dushake {
  margin-top: 10px;
  margin-left: 7px;
} */


.p2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
}

.head2 {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.pa2 {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;

}


.course .card:hover,
.project .card:hover,
.course1 .card:hover {
  cursor: pointer;
  color: rgb(19, 19, 23);
  color: #1BABE4;
  transition: all 0.4s;
  box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.57);
}

.tableheading {
  color: #1BABE4;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

}




.contactUs {
  display: inline-flex;
  justify-content: space-between;
  margin-left: 30%;
}

.contactus {
  margin-top: 80px;
  margin-right: 350px;

}

.contactlistone {
  color: #1BABE4;
}

.contactushead {
  color: #1BABE4;
  margin-left: 300px;
  margin-top: 80px;

}

.contactsection {
  justify-content: center;
}

.contactform {
  margin-left: 30%;
}

.contactinput {
  height: 50px;
  width: 520px;
  left: 145px;
  top: 7017px;
  border-radius: 4px;
}

.bothform {
  margin-top: 0px;

}

.subjectconctact {
  height: 50px;
  width: 1055px;
  left: 145px;
  top: 7087px;
  border-radius: 4px;
}

.textarea {
  height: 153px;
  width: 1055px;
  left: 145px;
  top: 7157px;
  border-radius: 4px;
}

.contactinputs {
  height: 50px;
  width: 520px;
  margin-left: 375px;
  border-radius: 4px;
}

.contactbtn {

  height: 46px;
  width: 100%;
  margin-left: 400px;
  border-radius: 4px;
  background: #1BABE4;
  color: #FFFFFF;
  margin-top: 15px;

}

#sb-btn {
  margin-left: 240px;
  margin-top: 20px;
}

.heacontact {
  color: #999999;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;

}


.partners {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-left: 200px;
  justify-items: center;
}

.partner11 {
  height: 226px;
  width: 224px;
  margin-top: 20px;

}

.partner22 {

  height: 308px;
  width: 306px;
  margin-left: 80px;
}

.partner33 {

  height: 226px;
  width: 224px;
  margin-left: 80px;
  margin-top: 20px;

}


@media (max-width: 768px) {
  .partners {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    justify-content: space-between;
  }

  .partner11,
  .partner22,
  .partner33 {
    margin-left: 0;
  }


  .partner11,
  .partner33 {
    margin-top: 5px;
  }
}


@media (max-width: 768px) {

  .partner11,
  .partner22,
  .partner33 {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
  }
}

.parheading {

  font-size: 23px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #1BABE4;
}

.partnerdivision {
  margin-top: 80px;
}




@media (max-width: 768px) {
  .contactus {
    margin-top: 20px;
    margin-right: 20px;
  }

  #sb-btn {
    width: 100%;
    margin-left: 0;

  }

  .contactUs {
    display: block;
    margin-left: 0;
  }

  .contactform {
    margin-left: 0;
    margin-top: 0;


  }

  .contactinput,
  .contactinputs,
  .subjectconctact,
  .textarea {
    width: 200%;
    margin-left: 0;
  }

  .contactinputs {
    margin-left: 117%;

  }

  .contactbtn {
    width: 100%;
    margin-left: 0;
  }

  .contactushead {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}

@keyframes bounceTop-1 {

  0%,
  100% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 997px) {
  .bwengeNews {
    margin-top: 4rem;
    border: 0px solid black;
  }







}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.homePage {
    font-family: 'Poppins', sans-serif;

}

.fieldGround {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 10px;
    padding-inline: 30px;
}

.fieldBox {
    background-color: rgb(179, 182, 187);
    padding: 10px;
    border-radius: 7px;
    display: grid;
    color: black;
    grid-template-rows: 40px 150px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.fieldName {
    border-bottom: 1px solid black;
    font-size: 1.5rem;
    font-weight: 800;
}

.paperTitles p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.paperTitles p:hover {
    color: rgb(255, 255, 255)
}

.secondary {
    bottom: 0;
    float: right;
    width: 90px;
    height: 45px;
}


@media screen and (max-width:2000px) {}

@media screen and (max-width:1150px) {
    .fieldGround {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 10px;
    }
}

@media screen and (max-width:650px) {
    .fieldGround {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 10px;
    }
} */

.overlay-container {
  position: relative;
}

.sliding {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: top; 
}


.over-lay {
  position: absolute;
  top: 0;
  bottom: 0;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  /* max-width: 600px;  */
  background-color: rgba(0, 0, 0, 0.6); 
  padding: 40px; 
  color: white;
  /* border-radius: 10px;  */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); 
}

.over-lay h2 {
  margin-bottom: 20px; 
  font-size: 28px; 
  font-weight: 700;
  line-height: 1.2; 
}

.over-lay h3 {
   margin-bottom: 30px;
  font-size: 18px; 
  line-height: 1.5; 
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.over-lay button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px; 
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px; 
  transition: background-color 0.3s ease; 
}

.over-lay a{
  text-decoration: none;
  color: #FFFFFF;
}
.text-slide {
  position: absolute;
  top: 50%;
  transform: translate(0, -30%);
}
.text-slide h2{
  font-size: 50px;
}

.over-lay button:hover {
  background-color: #0056b3; 
}

.slider-container {
  position: relative;
}

.slides {
  display: flex;
  overflow: hidden;
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.slide img {
  width: 100%;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 100;
}

.next {
  right: 0;
}

.indicators {
  text-align: center;
  margin-top: 10px;
}

.indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #717171;
}


/* community part */

/* .diaspora {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../imgs/diaspora.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.university {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../imgs/university.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.department {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../imgs/department.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
.diaspora{
  background-color: #4c9edb;
}
.university{
  background-color: #ebcd2f;
}
.department{
  background-color: #33cbe4;
}