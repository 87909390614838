.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  
}

.footer {
  bottom: 0px;
  margin-top: auto;
}
